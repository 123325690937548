const config = {
  gatsby: {
    pathPrefix: '/',
    siteUrl: 'https://developers-stg.gradual.com',
    gaTrackingId: null,
    trailingSlash: false,
  },
  header: {
    logo: 'https://cdn.gradual.com/images/https://d2xo500swnpgl1.cloudfront.net/uploads/web/Gradual-circle-wt-b7515ceb-1680-4268-af2b-eeb792cc7e75-1658415339220.png?fit=scale-down&width=100',
    logoLink: 'developers-stg.gradual.com',
    title: "<a href='https://developers-stg.gradual.com'></a>",
    helpUrl: '',
    tweetText: '',
    social: ``,
    links: [{ text: '', link: '' }],
    search: {
      enabled: true,
      indexName: 'public-stg',
      algoliaAppId: process.env.GATSBY_ALGOLIA_APP_ID,
      algoliaSearchKey: process.env.GATSBY_ALGOLIA_SEARCH_KEY,
      algoliaAdminKey: process.env.ALGOLIA_ADMIN_KEY,
    },
  },
  sidebar: {
    forcedNavOrder: [
    ],
    collapsedNav: [
    ],
    links: [],
    frontLine: false,
    ignoreIndex: true,
    title: "Gradual Public Docs",
  },
  siteMetadata: {
    title: 'Gradual Public Docs',
    description: 'Gradual Handbook for developer',
    ogImage: null,
    favicon: 'https://cdn.gradual.com/images/https://d2xo500swnpgl1.cloudfront.net/uploads/web/Gradual-circle-wt-b7515ceb-1680-4268-af2b-eeb792cc7e75-1658415339220.png?fit=scale-down&width=100',
  },
  pwa: {
    enabled: false, // disabling this will also remove the existing service worker.
    manifest: {
      name: 'Gatsby Gitbook Starter',
      short_name: 'GitbookStarter',
      start_url: '/',
      background_color: '#6b37bf',
      theme_color: '#6b37bf',
      display: 'standalone',
      crossOrigin: 'use-credentials',
      icons: [
        {
          src: 'src/pwa-512.png',
          sizes: `512x512`,
          type: `image/png`,
        },
      ],
    },
  },
};

module.exports = config;
